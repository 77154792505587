/* ... existing styles ... */

@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');

h1, h2 {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 1em;
}

/* Styles for the button container within the response section */
.button-container {
  display: flex;
  justify-content: center; /* Centers buttons horizontally */
  gap: 20px; /* Space between buttons */
}

/* Styles for each response button */
.response-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px; /* Adjusted for better readability */
  /* Added box-shadow for depth */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  font-weight: bold; /* Make the text bold */
}


/* ... existing styles ... */

/* Styles for the translations section */

/* ...existing styles... */

/* Styles for the translations toggle button */
.translation-toggle-button {
  background-color: #ff6347; /* A vivid color to grab attention */
  color: #fff; /* White text color for contrast */
  border: none; /* No border for a cleaner look */
  border-radius: 5px; /* Rounded corners */
  padding: 12px 25px; /* Slightly larger padding for a larger button */
  cursor: pointer;
  font-size: 18px; /* Larger font size for better visibility */
  font-weight: bold; /* Bold font weight for emphasis */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle box shadow for depth */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effects */
  margin-top: 20px; /* Spacing from the element above */
}

/* Hover effect for the translations toggle button */
.translation-toggle-button:hover {
  background-color: #ff7f50; /* Lighter shade for hover state */
  transform: translateY(-2px); /* Slight raise effect on hover */
}

/* ...any additional styles... */



.translations-section {
  background-color: #f7f7f7; /* Light background for the section */
  border-radius: 8px; /* Rounded corners for the section */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px; /* Spacing inside the section */
  margin-top: 20px; /* Spacing above the section */
  max-width: 600px; /* Maximum width of the section */
  margin-left: auto; /* Centering the section */
  margin-right: auto; /* Centering the section */
}

.translations-section h2 {
  color: #800000; /* Matching color to the theme */
  text-align: center; /* Center align the title */
  margin-bottom: 20px; /* Spacing below the title */
}


/* Audio Control Enhancements */
.translation-item {
  background: #fff; /* White background for each item */
  border-radius: 8px; /* Rounded corners */
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  margin-bottom: 20px; /* Add more space between items */
}

.translation-item:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}


.translation-item strong {
  color: #333; /* Dark text for the language name */
  display: block; /* Block display for better control */
  margin-bottom: 5px; /* Spacing below the language name */
}

/* Styles for the audio controls */
.translation-item audio {
  width: 100%; /* Full width for audio controls */
  margin-top: 10px; /* Spacing above the audio controls */
}

/* ... any additional styles ... */
/* For the button container within the response section */
.button-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 20px; /* Space between buttons */
}

/* Styles for each response button */
.response-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 40px; /* Increase horizontal padding for wider buttons */
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px; /* Text size */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Shadow for depth */
  /* Keep font-weight bold if you prefer bold text */
  font-weight: bold; /* Bold text */
}

/* Hover effect for buttons */
.response-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

/* Red color for the 'No' button */
#no-btn {
  background-color: #f44336; /* Red background */
}

/* Darker red on hover for 'No' button */
#no-btn:hover {
  background-color: #da190b; /* Darker red on hover */
}

/* Responsive design: stack buttons vertically on narrow screens */
@media (max-width: 600px) {
  .button-container {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }

  .response-button {
    width: 100%; /* Full width buttons on smaller screens */
    margin-bottom: 10px; /* Space between stacked buttons */
  }
}

/* Responsive design: stack buttons vertically on narrow screens */
@media (max-width: 600px) {
  .button-container {
    flex-direction: column; /* Stack buttons vertically */
  }

  .response-button {
    width: 100%; /* Full width buttons on smaller screens */
    margin: 10px 0; /* Margin for vertical stacking */
  }
}



/* Styles for the image gallery */
.image-gallery {
  margin-top: 30px;
}

.image-gallery img {
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 
  cursor: pointer;/* Smooth transition for transform and shadow */
}


.image-gallery img {
  transition: transform 0.3s ease;
}

.image-gallery img:hover {
  transform: scale(1.1);
  cursor: pointer;
}


/* Swiper styles */
.swiper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.swiper-slide img {
  width: 100%;
  height: 100%; 
  object-fit: cover; /* This will cover the area, cropping the image if necessary */
  object-position: center; /* Center the image within the element */
}


.swiper-slide {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


/* App.css */

.poem {
  font-family: 'Alex Brush', cursive;
  font-size: 1.5em;
  color: #722f37;
  font-weight: bold;
  padding: 20px;
  margin: 10px auto; /* Adjusted margin for top and bottom */
  max-width: 600px;
  text-align: center;
  animation: fadeIn 2s forwards;
  border: 2px solid #C0C0C0; /* Solid border with a golden color */
  border-radius: 15px; /* Adds rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for depth */
}

/* Remove before and after pseudo-elements if they're causing issues */
.poem::before, .poem::after {
  content: none;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}








/* ... any additional styles ... */
